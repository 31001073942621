<template>
    <v-container fluid>
        <v-parallax
            class="blur"
            :height="$vuetify.breakpoint.smAndDown ? 900 : 500"
            src="../assets/conference.jpg"
            dark>
            <v-container fluid fill-height>
                <v-row class="text-center" justify="center" 
              align="center">
                    <v-col cols="12" class="mb-10">
                        <h1
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                            class="font-weight-bold mb-2 mt-2">
                            Nos Moyens Pédagogiques
                        </h1>
                    </v-col>

                    <v-col 
                        class="mx-auto mb-3"
                        cols="12"
                        md="3">
                        <h1
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
                            class="font-weight-black">
                            Une grande salle de conferences</h1>
                        </v-col>
                    <v-col 
                        class="mx-auto mb-3"
                        cols="12"
                        md="3">
                        <h1
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
                            class="font-weight-black">
                            3 <br> Classes de cours/ ateliers</h1>
                    </v-col>
                    <v-col 
                        class="mx-auto mb-3"
                        cols="12"
                        md="3">
                        <h1
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
                            class="font-weight-black">
                            2 <br> Grandes salles de detente</h1>
                    </v-col>
                    <v-col 
                        class="mx-auto mb-3"
                        cols="12"
                        md="3">
                        <h1
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']"
                            class="font-weight-black">
                            Une kichinette & une salle de soins et prière</h1>
                    </v-col>
                   
        
                    <v-col
                        class="text-center mx-auto "
                        cols="12"
                        >
                        <router-link 
                                
                            class="black--text font-weight-bold"
                            style="text-decoration:none" 
                            to="/galerie" target="_blank">
                            <v-btn
                                class="align-self-end"
                                color="white"
                                elevation="10"
                                x-large
                            >
                            
                                Visitez notre école
                                <v-icon class="ml-2">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col
                        class="text-center mx-auto "
                        cols="12"
                        >
                        <v-btn
                            class="align-self-end"
                            elevation="5"
                            fab
                            x-large
                            @click="$vuetify.goTo('#formations')"
                        >
                            <v-icon>fas fa-chevron-down</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
          </v-container>
        </v-parallax>
    </v-container>
         

</template>
<script>
export default {
    name: 'Pedago',
}
</script>